// One Step Checkout
$osc_checkout__title-color                              : $color-text-default !default;
$osc_checkout__title-font-weight                        : $font-weight-bold !default;
$osc_checkout__title_font-size                          : 20px !default;
$osc_checkout__authentication-font-size                 : $font-size-small !default;
$osc_checkout__authentication-link-color                : $font-color-link-accent !default;
$osc_checkout__step-title-padding                       : $spacer $spacer--medium !default;
$osc_checkout__step-title-font-weight                   : $font-weight-bold !default;
$osc_checkout__step-title-background                    : #f9f9f9 !default;
$osc_checkout__step-title-color                         : $color-text-default !default;
$osc_checkout__field-label-color                        : $color-text-accent !default;
$osc_checkout__field-label-font-weight                  : $font-weight-semi-bold !default;
$osc_checkout__field-border                             : $border-base !default;
$osc_checkout__field-border-radius                      : $border-radius !default;
$osc_checkout__field-height                             : 45px !default;
$osc_checkout__method-price-font-size                   : $font-size-base !default;
$osc_checkout__method-label-color                       : $color-text-default !default;
$osc_checkout__shipping-method-border                   : $border-base !default;
$osc_checkout__shipping-method-border-radius            : $border-radius !default;
$osc_checkout__shipping-method-color                    : $color-text-accent !default;
$osc-checkout__shipping-method-box-shadow               : 0 1px 0 0 $gray-light !default;
$multi-step-checkout__field-group-background            : $color-light--accent !default;
$osc_checkout__shipping-method--active-background       : $color-primary--accent-10 !default;
$osc_checkout__shipping-method--active-border-color     : $color-primary !default;
$osc_checkout__shipping-method--active-box-shadow       : 0 1px 0 0 $color-primary !default;
$osc_checkout__shipping-method--active-color            : $color-text-accent !default;
$osc_checkout__shipping-method-height                   : 40px !default;
$osc_checkout__shipping-method-height\@large            : 50px !default;
$osc_checkout__payment-method-border                    : $border-base !default;
$osc_checkout__payment-method-border-radius             : $border-radius !default;
$osc_checkout__payment-method-color                     : $color-text-accent !default;
$osc_checkout__payment-method-height                    : 60px !default;
$osc_checkout__payment-method-extended-height           : 65px !default;
$osc_checkout__payment-method-height\@large             : 60px !default;
$osc_checkout__payment-method-extended-height\@large    : 75px !default;
$osc_checkout__payment-method--active-background        : $color-primary--accent-2 !default;
$osc_checkout__payment-method--active-border-color      : $color-primary !default;
$osc_checkout__dispatch-status-font-size                : $font-size-base !default;
$multi-step-checkout__footer-usp-color                  : $color-text-accent !default;
$multi-step-checkout__link-color                        : $color-text-accent !default;
$checkout-shipping-address__max-width                   : 580px !default;

$checkmark-encoded-url : "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNMzYyLjYgMTkyLjlMMzQ1IDE3NC44Yy0uNy0uOC0xLjgtMS4yLTIuOC0xLjItMS4xIDAtMi4xLjQtMi44IDEuMmwtMTIyIDEyMi45LTQ0LjQtNDQuNGMtLjgtLjgtMS44LTEuMi0yLjgtMS4yLTEgMC0yIC40LTIuOCAxLjJsLTE3LjggMTcuOGMtMS42IDEuNi0xLjYgNC4xIDAgNS43bDU2IDU2YzMuNiAzLjYgOCA1LjcgMTEuNyA1LjcgNS4zIDAgOS45LTMuOSAxMS42LTUuNWguMWwxMzMuNy0xMzQuNGMxLjQtMS43IDEuNC00LjItLjEtNS43eiIgZmlsbD0iIzk2YzExZiIvPjwvc3ZnPg==" !default;
$progress-bar-faded-color : #cbe08f !default;
