// @see wwl/theme-frontend-base-components/components/01-globals/variables/_variables.scss
$footer__background-color\@large                            : $color-primary--accent-9 !default;
$footer__handler--with-top-separator-border                 : none !default;
$footer__handler--link-list-background-color                : $color-primary !default;
$footer__handler--link-list-background-color\@large         : $color-light !default;
$footer__handler--trustpilot-border                         : none !default;

$link-list__item-color                                      : $color-light !default;
$link-list__item-color-\@large                              : $color-text-accent !default;

$need-help__text-color                                      : $color-text-accent !default;
$need-help__subtitle-text-color                             : $color-text-accent-3 !default;
$need-help__text-color-\@large                              : $color-text-accent !default;
$need-help__subtitle-text-color\@large                      : $color-text-accent-3 !default;
$need-help__heading-font-weight                             : $font-weight-bold !default;
$need-help__heading-font-weight-\@large                     : $font-weight-bold !default;
$need-help__heading-font-size                               : 19px !default;
$need-help__heading-font-size-\@large                       : 19px !default;
$need-help__button-background                               : $color-primary !default;
$need-help__button-hover-background                         : $color-primary--accent !default;
$need-help__button-color                                    : $color-light !default;
$need-help__button-background-\@large                       : $color-primary !default;
$need-help__button-color-\@large                            : $color-light !default;
$need-help__button-border-bottom                            : 2px solid rgba(71, 71, 71, 0.5) !default;

$need-help__button--hover-background                        : $color-primary--accent !default;
$need-help__button--hover-color                             : #fff !default;

$need-help__subtitle-text-color--light                      : $color-text-accent-3 !default;

$newsletter__heading-color                                  : $color-text-accent !default;
$newsletter__heading-font-weight                            : $font-weight-bold !default;
$newsletter__heading-font-size                              : 19px !default;
$newsletter__button-color                                   : $color-cta--accent-2 !default;
$newsletter__button-border-bottom                           : 2px solid rgba(55, 55, 55, 0.5) !default;
$need-help__heading-color--light                            : $color-text-accent !default;
$need-help__heading-third-color--light                      : $color-text-accent !default;

$footer-menu-border-color                                   : #adcf54 !default;