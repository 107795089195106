// @see wwl/theme-frontend-base-components/components/01-globals/variables/_variables.scss
$volume-discount__table-background: $color-primary--accent-9 !default;
$volume-discount--light__table-color: $color-text-default;
$volume-discount__desktop-reverse : true !default;
$volume-discount__text-accent-color: $color-primary !default;
$dispatch-status-color: $color-text-default;
$dispatch-status--detailed-icon-fill: $color-text-default;

// Ribbon
$ribbon-background: #fff !default;
$ribbon--alt-background: $color-alt--accent-3 !default;
$ribbon-color: $color-text-default !default;
$ribbon--alt-color: $color-alt !default;
$ribbon-font-size: $font-size-base !default;
$ribbon-strong-font-weight: $font-weight-semi-bold !default;
$ribbon-border-left: 5px solid #0b92e0 !default;
$ribbon-border-right: 1px solid #ebebeb !default;
$ribbon-border-top: 1px solid #ebebeb !default;
$ribbon-border-bottom: 1px solid #ebebeb !default;
$ribbon-border-radius: 5px !default;

// Product Specifications
$product-specifications__title-color: $color-text-accent !default;
$product-specifications__label-color: $color-primary !default;
$product-specifications__title-font-weight: $font-weight-bold !default;
$product-specifications__label-border: $base-border !default;
$product-specifications__label-font-weight: $font-weight-semi-bold !default;
$product-specifications__icon-fill: $color-text-accent !default;
$product-specifications__with-icons-background: $color-primary !default;
$product-specifications__with-icons-background\@medium: transparent !default;

$attribute__title-color: $color-text-default !default;

$dropdown-item__item-color: $color-text-default !default;
$dropdown-item__item-color-hover: $color-text-default !default;

$table__background-even--odd-even: #fbfbfb !default;

$link-icon__hover-color: $color-text-default !default;
$link-icon-icon__fill: $color-text-default !default;
$trustpilot__title-color: $color-text-default !default;

$related-products__title-color              : $color-text-accent !default;
$related-products__title-color--alt         : $color-text-accent !default;
$related-products__title-font-weight        : $font-weight-bold !default;

$badge__color                               : $color-primary !default;
$badge__border                              : 1px solid $color-primary !default;

$product-view__installer-contact-background : $color-primary--accent-10 !default;
$product-view__installer-contact-color      : $color-text-accent !default;

$product-view__stock-margin-top             : 0 !default;