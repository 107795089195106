// ### Buttons ###
$header-button__icon-color: $color-text-default !default;
$header-button__text-color: $color-text-default !default;
$header-button__icon-color--hover: $color-text-default !default;

// Light Secondary
$button__background-hover--light-secondary: $color-light--accent !default;
$button__font-color-hover--light-secondary: $color-light !default;

// Light Secondary Inverted
$button__background--light-secondary-inverted: $color-light--accent !default;
$button__box-shadow--light-secondary-inverted: 0 2px 0 0 rgba(168, 168, 168, 0.5) !default;
$button__background-hover--light-secondary-inverted: rgba(168, 168, 168, 0.15) !default;
$button__border-color-hover--light-secondary-inverted: transparent !default;

$button__box-shadow                 : inset 0 1px 0 0 rgba(0, 0, 0, 0.5) !default;
$button__box-shadow--cta            : 0 2px 0 0 rgb(153, 91, 40) !default;

// Alt
$button__background--alt            : $color-primary !default;
$button__background-hover--alt      : $color-primary--accent !default;
$button__box-shadow--alt            : 0 2px 0 0 #6f8437 !default;

// Light
$button__font-color--light            : $color-text-accent !default;
$button__font-color-hover--light      : $color-text-accent !default;